.trees {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
}

.tree {
    width: 45%;
    background: #f9f9f9;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    min-height: 300px;
    text-align: center;
}

.tree h3 {
    text-align: center;
    margin-bottom: 10px;
}

.tree-item {
    padding: 10px;
    margin: 5px 0;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: grab;
    text-align: left;
    display: flex;
    align-items: center;
}

.tree-node {
    margin-left: 20px;
}

.children {
    margin-left: 20px;
    border-left: 1px dashed #ddd;
    padding-left: 10px;
}

.toggle-button {
    cursor: pointer;
    margin-right: 5px;
    font-weight: bold;
    display: inline-block;
    color: #333;
}


.tree-connector {
    position: relative;
}

.tree-connector::before {
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    bottom: 0;
    width: 1px;
    background-color: #ddd; /* Tree line color */
}
